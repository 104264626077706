import DashboardLayout from "@/pages/layouts/DashboardLayout";

let auditionRoutes = {
  path: '/auditions',
  component: DashboardLayout,
  redirect: '/auditions/list',
  children: [
    {
      path: 'list',
      name: 'Audition List',
      component:()=>import(/* webpackChunkName: "auditions" */ '@/pages/general/auditions/AuditionList'),
      meta: {
        permissionsCodes: ['auditions/index'],
      }
    },
    {
      path: 'show/:id',
      name: 'Audition Details',
      component:()=>import(/* webpackChunkName: "auditions" */ '@/pages/general/auditions/AuditionView'),
      meta: {
        permissionsCodes: ['auditions/get'],
      }
    },
  ]
}

export default auditionRoutes;
