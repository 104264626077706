import DashboardLayout from "@/pages/layouts/DashboardLayout";

let performerRoutes = {
  path: '/performers',
  component: DashboardLayout,
  redirect: '/performers/list',
  children: [
    {
      path: 'list',
      name: 'Performer List',
      component: () => import(/* webpackChunkName: "performers" */  '@/pages/performers/PerformerList'),
      meta: {
        permissionsCodes: ['performers/index','restricted_own/performers/index'],
      }
    },
    {
      path: 'create/',
      name: 'Add Performer',
      component: () => import(/* webpackChunkName: "performers" */  '@/pages/performers/PerformerForm'),
      meta: {
        permissionsCodes: ['performers/create'],
      }
    },
    {
      path: 'edit/:id/',
      name: 'Edit Performer',
      component: () => import(/* webpackChunkName: "performers" */  '@/pages/performers/PerformerForm'),
      meta: {
        permissionsCodes: ['performers/update','restricted_own/performers/update'],
      }
    }
  ]
}

export default performerRoutes;
